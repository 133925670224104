export const getLocalStorageData = () => {
  return {
    departureDates: JSON.parse(localStorage.getItem("departureDates")) || [],
    departureAirports:
      JSON.parse(localStorage.getItem("departureAirports")) || [],
    arrivalAirports: JSON.parse(localStorage.getItem("arrivalAirports")) || [],
    adults: parseInt(localStorage.getItem("adults")) || 1,
    kids: parseInt(localStorage.getItem("kids")) || 0,
    children: parseInt(localStorage.getItem("children")) || 0,
    infants: parseInt(localStorage.getItem("infants")) || 0,
  };
};

export const buildOriginDestinationInfo = (
  departureDates,
  departureAirports,
  arrivalAirports
) => {
  return departureDates.map((date, index) => ({
    // RPH: (index + 1).toString(),
    RPH: (index + 1).toString(),
    DepartureDateTime: `${date}T00:00:00`,
    OriginLocation: { LocationCode: departureAirports[index] },
    DestinationLocation: { LocationCode: arrivalAirports[index] },
  }));
};

export const buildPassengerTypes = (adults, kids, children, infants) => {
  const passengerTypes = [{ Code: "ADT", Quantity: adults }];

  if (kids > 0) passengerTypes.push({ Code: "C06", Quantity: kids });
  if (children > 0) passengerTypes.push({ Code: "C03", Quantity: children });
  if (infants > 0) passengerTypes.push({ Code: "INF", Quantity: infants });

  return passengerTypes;
};

export const processItinerary = (data) => {
  if (
    !data.groupedItineraryResponse ||
    !data.groupedItineraryResponse.itineraryGroups
  ) {
    console.error(
      "Missing groupedItineraryResponse or itineraryGroups in data:",
      data
    );
    return {
      error: "This date has no schedule, please search for a new date",
    };
  }
  return data.groupedItineraryResponse.itineraryGroups.flatMap((group) => {
    return group.itineraries.map((itinerary) => {
      const legs = itinerary.legs.map((leg, index) => {
        const legDesc = data.groupedItineraryResponse.legDescs.find(
          (ld) => ld.id === leg.ref
        );

        const schedules = legDesc
          ? legDesc.schedules.map((schedule) => {
              return data.groupedItineraryResponse.scheduleDescs.find(
                (sd) => sd.id === schedule.ref
              );
            })
          : [];

        const legDescription =
          group.groupDescription.legDescriptions[index] || {};
        const departureDate = legDescription.departureDate;
        const departureLocation = legDescription.departureLocation;
        const arrivalLocation = legDescription.arrivalLocation;

        const transits = [];
        const sharedFlights = [];
        for (let i = 0; i < schedules.length - 1; i++) {
          const currentSchedule = schedules[i];
          const nextSchedule = schedules[i + 1];
          if (
            currentSchedule.arrival.airport === nextSchedule.departure.airport
          ) {
            const arrivalTime = new Date(
              `${departureDate}T${currentSchedule.arrival.time}`
            );
            const departureTime = new Date(
              `${departureDate}T${nextSchedule.departure.time}`
            );
            const layoverTime = Math.abs(departureTime - arrivalTime) / 60000;
            transits.push({
              airport: currentSchedule.arrival.airport,
              arrivalTime: currentSchedule.arrival.time,
              departureTime: nextSchedule.departure.time,
              elapsedTime: nextSchedule.elapsedTime,
              layoverTime,
            });
          } else {
            sharedFlights.push(i + 1);
          }
        }

        return {
          ...legDesc,
          schedules,
          rph: index + 1,
          transits,
          sharedFlights,
          departureDate,
          departureLocation,
          arrivalLocation,
        };
      });

      const totalPassengers = itinerary.pricingInformation.reduce(
        (sum, pricingInfo) => {
          return (
            sum +
            pricingInfo.fare.passengerInfoList.reduce(
              (passengerSum, passengerInfo) => {
                return (
                  passengerSum + passengerInfo.passengerInfo.passengerNumber
                );
              },
              0
            )
          );
        },
        0
      );

      return { ...itinerary, legs, totalPassengers };
    });
  });
};
