import { call, put, takeLatest } from "redux-saga/effects";
import {
  setItineraries,
  setAirlines,
  setLegDesc,
  setIsLoading,
  setError,
  fetchItinerary,
  fetchAirlines,
} from "../itinerarySlice";
import { fetchItineraryData } from "../../utils/api";

import {
  // getLocalStorageData,
  buildOriginDestinationInfo,
  buildPassengerTypes,
  processItinerary,
} from "@/utils/searchResultFunction";
function* fetchItinerarySaga() {
  yield put(setIsLoading(true));
  // console.log("Saga started");
  try {
    const searchParams = new URLSearchParams(window.location.search);

    const departureAirports = searchParams.get("origin").split(",");
    const arrivalAirports = searchParams.get("destination").split(",");
    const date = searchParams.get("startDate").split(",");
    // console.log(date);
    // console.log(searchParams);
    // const {
    //   // departureDates,
    //   // departureAirports,
    //   // arrivalAirports,
    //   adults,
    //   kids,
    //   children,
    //   infants,
    // } = getLocalStorageData();
    const adults = parseInt(searchParams.get("adults")) || 1;
    const kids = parseInt(searchParams.get("kids")) || 0;
    const children = parseInt(searchParams.get("children")) || 0;
    const infants = parseInt(searchParams.get("infants")) || 0;

    // console.log(departureAirports, "departureAirports");

    const originDestinationInfo = buildOriginDestinationInfo(
      date,
      departureAirports,
      arrivalAirports
    );
    const passengerTypes = buildPassengerTypes(adults, kids, children, infants);

    const data = yield call(
      fetchItineraryData,
      originDestinationInfo,
      passengerTypes
    );

    // console.log("Fetched itinerary data:", data);
    const processedData = processItinerary(data);

    yield put(setItineraries(processedData));
    const legDescs = data.groupedItineraryResponse.legDescs;
    yield put(setLegDesc(legDescs));
  } catch (error) {
    yield put(setError(error.message));
    console.error("Error fetching itinerary data:", error);
  } finally {
    yield put(setIsLoading(false));
    // console.log("Loading state set to false");
  }
}

function* fetchAirlinesSaga() {
  yield put(setError(null));
  try {
    const response = yield call(
      fetch,
      "https://api.hamsfly.com/api/carrierLogo"
    );
    if (!response.ok) {
      throw new Error("Failed to fetch airline data");
    }
    const data = yield response.json();
    yield put(
      setAirlines(
        data.carrierLogo.map(({ code, logo, name }) => ({ code, logo, name }))
      )
    );
  } catch (error) {
    yield put(setError(error.message));
    console.error("Error fetching airlines:", error);
  }
}

export function* itinerarySaga() {
  yield takeLatest(fetchItinerary.type, fetchItinerarySaga);
  yield takeLatest(fetchAirlines.type, fetchAirlinesSaga);
}
