import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardData: {},
};

const dashboardDataSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    reset: (state) => {
      state.dashboardData = initialState.dashboardData;
    },
  },
});

export const { setDashboardData, reset } = dashboardDataSlice.actions;

export default dashboardDataSlice.reducer;
